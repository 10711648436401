import { useState, useLayoutEffect, useRef } from 'react';
import '../../assets/css/shared/navbar.scss';
import { Navbar, Collapse, Nav, NavbarToggler, UncontrolledDropdown, DropdownMenu, DropdownToggle, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { ReactComponent as Down16 } from '../../assets/icons/down-large-16.svg';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Remove48 } from '../../assets/icons/remove48.svg';
import { ReactComponent as Hamburger48 } from '../../assets/icons/hamburger-48.svg';
import routeConfig from './routeConfig';
import MobyteTeal from "../../assets/images/mobyte-blueteal.png"

const NavbarLoggedIn = ({ loggedInName, userLevel }) => {
    const { instance } = useMsal();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStates, setDropdownStates] = useState({});

    const toggle = () => setIsOpen(!isOpen);

    const handleLogout = () => {
        instance.logoutRedirect().catch(console.error);
    };

    const dropdownStatesRef = useRef({});


    const handleDropdown = (event) => {
        const elementID = event.target.id.split(":")[0];

        // For larger screens (>= 992px)
        if (window.innerWidth >= 992) {
            if (event.type === 'mouseenter') {
                dropdownStatesRef.current[elementID] = true; // Show the dropdown on hover
            } else {
                dropdownStatesRef.current[elementID] = false; // Hide the dropdown on mouse leave
            }
        } else {
            // For smaller screens (< 992px)
            if (event.type === 'click') {
                // Close all dropdowns first, then toggle the clicked one
                Object.keys(dropdownStatesRef.current).forEach(key => {
                    if (key !== elementID) {
                        dropdownStatesRef.current[key] = false; // Close other dropdowns
                    }
                });

                // Toggle the clicked dropdown
                dropdownStatesRef.current[elementID] = !dropdownStatesRef.current[elementID];
            }
        }

        // Update state to trigger re-render and reflect the changes visually
        setDropdownStates({ ...dropdownStatesRef.current });
    };

    const handleLink = () => {
        setDropdownStates({});
    };

    useLayoutEffect(() => {
        setIsOpen(false);
    }, [location]);

    const CustomDropdownItem = ({ id, icon, label, to }) => (
        <Col className="p-2 nav-drp-link" tag={Link} to={to} onClick={handleLink} id={`${id}:Col`}>
            {/* {icon}
            &nbsp;&nbsp; */}
            <span id={`${id}:span`}>{label}</span>
        </Col>
    );

    const renderNavbarItems = () => {
        const navItems = routeConfig.reduce((acc, route) => {
            // Group routes by category (e.g., 'manageData', 'reports', 'general')
            const category = route.category || 'general'; // Default to 'general' if category is undefined
            if (!acc[category]) acc[category] = [];
            if (route.access.includes(userLevel)) { // Only include routes that the user has access to
                acc[category].push(route);
            }
            return acc;
        }, {});

        return (
            <>
                {navItems['general']?.map((route) => (
                    <Col key={route.path} className="me-5 mt-2 mb-2 ms-2 pe-2 nav-drp-link" tag={Link} to={route.path} onClick={handleLink}>
                        <div className="nav-text">
                            {route.name}
                        </div>
                    </Col>
                ))}
                {navItems['manageData'] && (
                    <UncontrolledDropdown
                        inNavbar
                        nav
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onClick={handleDropdown}
                        isOpen={dropdownStates.manageDataOpen}
                        style={{ cursor: "default" }}
                        id="manageDataOpen:drop"
                    >
                        <DropdownToggle nav className="me-5 ms-2" id="manageDataOpen:dropToggle"

                        >
                            <span id="manageDataOpen:span" className="nav-text">Manage Data <Down16 id="manageDataOpen:down" /></span>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-left-wide" id="manageDataOpen:dropMenu">
                            {navItems['manageData'].map(route => (
                                <CustomDropdownItem
                                    key={route.path}
                                    id={`manageDataOpen:${route.path}`}
                                    label={route.name}  // Use the user-friendly name
                                    to={route.path}
                                />
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
                {navItems['reports'] && (
                    <UncontrolledDropdown
                        inNavbar
                        nav
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onClick={handleDropdown}
                        isOpen={dropdownStates.reportsOpen}
                        style={{ cursor: "default" }}
                        id="reportsOpen:drop"
                    >
                        <DropdownToggle nav className="me-5 ms-2" id="reportsOpen:dropToggle"
                        >
                            <span id="reportsOpen:span" className="nav-text">Reports <Down16 id="reportsOpen:arrow" /></span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-left-wide" id="reportsOpen:dropMenu">
                            {navItems['reports'].map(route => (
                                <CustomDropdownItem
                                    key={route.path}
                                    id={`reportsOpen:${route.path}`}
                                    label={route.name}
                                    to={route.path}
                                />
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
            </>
        );
    };

    return (
        <Navbar className="nav-bg fixed-top" dark expand="lg">
            <div className="mt-1 mb-1">
                <Link to='/' className="nav-brand">
                    <img src={MobyteTeal} alt="Mobyte logo" className="logo-style" />

                </Link>
            </div>
            <NavbarToggler onClick={toggle} className="p-0 border-0">
                {isOpen ? <Remove48 /> : <Hamburger48 />}
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="ms-auto">
                    {renderNavbarItems()}

                    <UncontrolledDropdown
                        inNavbar
                        nav
                        isOpen={dropdownStates.profileOpen}
                        style={{ cursor: "default" }}
                        id="profileOpen:drop"
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onClick={handleDropdown}
                    >
                        <DropdownToggle nav className="me-5 ms-2" id="profileOpen:dropToggle"

                        >
                            <span id="profileOpen:span" className="nav-text">{loggedInName} <Down16 id="profileOpen:arrow" /></span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-left" id="profileOpen:dropMenu">
                            <Col className="p-2" id="profileOpen:Col">
                                <div className="nav-text mt-1 ms-3" style={{ cursor: "pointer" }} onClick={handleLogout}>
                                    <span>Logout</span>
                                </div>
                            </Col>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default NavbarLoggedIn;
