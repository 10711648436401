import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import Spinner from './components/shared/loading.js'
import PrivateRoutes from './components/shared/PrivateRoute.js'
import ReactGA from 'react-ga4';
import Cookies from 'universal-cookie';

const App = () => {
  const { inProgress } = useMsal();
  const [isBrowser, setIsBrowser] = React.useState(false);

  var cookies = new Cookies();
  const accepted = cookies.get("cookiesAccepted");

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    // Initialize ReactGA if it has not been initialized yet
    if (accepted === "accept" && !ReactGA.ga) { // Check if `ReactGA.ga` is defined
      ReactGA.initialize('G-B6DJJKMSBP');
    }
  }, [accepted]);

  const location = useLocation();
  useEffect(() => {
    if (accepted === "accept") {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location, accepted]);

  return (
    <>
      {
        isBrowser && inProgress !== InteractionStatus.None ? (
          <div className="main-container">
            <div className="login-spinner">
              {inProgress === InteractionStatus.Login && (
                <div className="hero-text-4 pb-5">Redirecting to login page</div>
              )}
              <Spinner />
            </div>
          </div>
        ) : (
          <PrivateRoutes />
        )
      }

    </>
  );
}

export default App;
