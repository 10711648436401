import { Spinner } from "reactstrap"


const Loader = () => {
    return (
        <>
            <div className="col-12">
                <Spinner style={{ width: '2rem', height: '2rem' }} />
            </div>
        </>
    )
}

export default Loader


