import React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineFileText } from 'react-icons/ai'
import { Col, Row, CardHeader, CardBody, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService'
import Spinner from '../../shared/loading'

const ToInvoice = ({ closeModal, showModalForm, displayModal, invCreate, loading4, setLoading5 }) => {

    const gridRef = useRef();
    const [budgetData, setBudgetData] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(showModalForm)
    const [expLoading, setExpLoading] = useState(true);

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    const isFirstColumn = useCallback((params) => {
        if (invCreate) {
            var displayedColumns = params.columnApi.getAllDisplayedColumns();
            var thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        }

    }, [invCreate]);

    const createInvoices = () => {
        let selectedNodes = gridRef.current.api.getSelectedRows()
        console.log(JSON.stringify(selectedNodes).length)
        if (JSON.stringify(selectedNodes).length !== 2) {
            // alert(`${JSON.stringify(selectedNodes)}`)
            setLoading5(true);
            toggleModal()
            base.add("FreeAgent/createinvoices", selectedNodes).then(() => {
                //console.log("create invoices FA")

                base.getAll("FreeAgent/refreshinvoices").then(() => {
                    //console.log("refresh invoice data")

                    setLoading5(false);
                });
            });
        }
        else {
            alert('No invoice records selected!')
        }
        //console.log("refresh data")

    };


    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn
        });



        setBudgetColDef([
            {
                headerName: "First Name",
                field: "firstName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125,
                checkboxSelection: invCreate,
            },
            {
                headerName: "Last Name",
                field: "lastName",

                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Client",
                field: "organisationName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Project",
                field: "projName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Value",
                field: "invoiceValue",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125,
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',

            },
            {
                headerName: "Hours",
                field: "sumHours",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Days",
                field: "days",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Terms",
                field: "defaultPaymentTermsInDays",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "include_timeslips",
                field: "include_timeslips",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, [currencyFormatter, invCreate, isFirstColumn]);

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("Report/toinvoice").then((res) => {
                setBudgetData(res);
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])
    }, [])



    // const onSelectionChanged = useCallback((event) => {
    //     var rowCount = event.api.getSelectedNodes().length;
    //     window.alert('selection changed, ' + rowCount + ' rows selected' + event.node.isSelected());
    // }, []);

    return (
        <>
            {!displayModal ?
                <>

                    <CardHeader className="pt-3 pb-3">
                        <Row>
                            <Col xs="12" sm="9" className="page-header">
                                <AiOutlineFileText size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                                <span className="section-heading">To be Invoiced</span>
                            </Col>

                        </Row>
                    </CardHeader>
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                {expLoading ?
                                    <CardBody style={{ height: "750px" }}>
                                        <div className="row align-items-center">
                                            <div className="text-center" >
                                                <Spinner />
                                            </div>
                                        </div>
                                    </CardBody>
                                    :
                                    <>
                                        {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                        <CardBody style={{ height: "680px" }}>
                                            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={budgetData}
                                                    columnDefs={budgetColDef}
                                                    overlayNoRowsTemplate="No Records Found"
                                                    defaultColDef={gridDefaultColDef}
                                                    pagination={true}
                                                    // onGridReady={onGridReady}
                                                    rowSelection={'single'}
                                                    // rowSelection={'single'}
                                                    paginationPageSize={20}
                                                // gridOptions={gridOptions}
                                                // onRowDoubleClicked={onSelectionChanged}
                                                // onGridReady={onGridReady}
                                                // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                                // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                                // onRowDoubleClicked=(()=> editRow())
                                                >
                                                </AgGridReact>
                                            </div>
                                        </CardBody>
                                    </>

                                }

                            </Col>

                        </Row >
                    </div>
                    {/* {showModal && <BudgetModal closeModal={closeModal} showModalForm={true} />} */}
                    {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

                </>
                :
                <Modal isOpen={showModal} size="xl" toggle={toggleModal} backdrop="static">
                    <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">To be Invoiced</div></ModalHeader>
                    {expLoading ?
                        <div className="row align-items-center p-5">
                            <div className="text-center" >
                                <Spinner />
                            </div>
                        </div>
                        :
                        <>
                            <ModalBody>
                                <Row>
                                    <Col xs="12">
                                        {expLoading ?
                                            <CardBody style={{ height: "500px" }}>
                                                <div className="row align-items-center">
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                            </CardBody>
                                            :
                                            <>
                                                <div style={{ height: "500px" }}>
                                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                                        <AgGridReact
                                                            ref={gridRef}
                                                            rowData={budgetData}
                                                            columnDefs={budgetColDef}
                                                            overlayNoRowsTemplate="No Records Found"
                                                            defaultColDef={gridDefaultColDef}
                                                            pagination={true}
                                                            rowSelection={'multiple'}
                                                            rowMultiSelectWithClick={invCreate}

                                                            paginationPageSize={20}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </>

                                        }

                                    </Col>

                                </Row >
                            </ModalBody>
                            <ModalFooter>
                                {invCreate &&
                                    <Button color="success" size="sm" className="btn-text b-width" onClick={createInvoices}>Create</Button>
                                }
                                <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Close</Button>
                            </ModalFooter>
                        </>
                    }
                </Modal>
            }
        </>

    )
}

export default ToInvoice
