import React, { useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
import simg_KM from '../../../assets/images/kmcnulty.jpeg';
import simg_AS from '../../../assets/images/ashepherd.JPG';
import simg_LW from '../../../assets/images/profile.jpg';
import simg_LP from '../../../assets/images/lpurser_1.jpg';
import simg_KK from '../../../assets/images/kknowler.jpg';
import TextOnImage from '../../shared/textOnImage';
import image from '../../../assets/images/team1.jpg';
import '../../../assets/css/mainsite/image.scss';

const TeamSection = ({ title, team }) => (
    <>
        <Row className="block-left-scroll visible">
            <div className="hero-text-2 secondary-text heading-block">
                {title}
            </div>
        </Row>
        <Row className="text-center pb-5 block-left-scroll visible">
            {team.map((member, index) => (
                <Col key={index} xs="12" lg="3" md="4" className="pb-3">
                    <div>
                        <div className="circular--portrait">
                            {member.image === null ? (
                                <FaUser size={140} color="#cecece" />
                            ) : (
                                <img src={member.image} alt={member.name} />
                            )}
                        </div>
                    </div>
                    <br />
                    <h3>{member.name}</h3>
                    <div className="text-muted text-wide">{member.title}</div>
                </Col>
            ))}
        </Row>
    </>
);

const Team = () => {
    const seniorManagement = [
        { name: 'Lawrence Whale', title: 'Managing Director | Technical Lead', image: simg_LW },
        { name: 'Mike Whale', title: 'Operations Director | Technical Specialist', image: null },
        { name: 'Anne Whale', title: 'Director | Finance', image: null },
    ];

    const developmentTeam = [
        { name: 'Alex Shepherd', title: 'Developer | Full Stack', image: simg_AS },
        { name: 'Laura Purser', title: 'Developer | Frontend', image: simg_LP },
    ];

    const administrationTeam = [
        { name: 'Jodie Whale', title: 'Resource Manager | Operations', image: null },
        { name: 'Katherine McNulty', title: 'Marketing Manager | Commercial', image: simg_KM },
        { name: 'Kathy Knowler', title: 'Administrator | Finance', image: simg_KK },
    ];

    useEffect(() => {
        document.title = 'Team | Our team of IT experts | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content =
            'Meet the talented and diverse team behind Mobyte. Our dedicated professionals bring expertise in software engineering, IT consulting, and development.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content =
            'Team, Mobyte, talent, software engineering, IT consulting, development, expertise, collaboration, innovation, diversity, professionals, passionate, results-driven';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);
        };
    }, []);

    const subheading = (
        <span className="hero-text-1 white-text">Team</span>
    );

    return (
        <div className="">
            <TextOnImage
                imageUrl={image}
                heading={"About us"}
                subheading={subheading}
                fromHome={false}
                altText={"Team working around desk"}
            />
            <div className="container-large">
                <div className="heading-block">
                    <h2 className="hero-text-2 secondary-text">
                        Our team of <span className="header-brush">IT experts</span>&nbsp;
                    </h2>
                </div>
                <TeamSection title="Senior Management" team={seniorManagement} />
                <TeamSection title="Development Team" team={developmentTeam} />
                <TeamSection title="Administration Team" team={administrationTeam} />
            </div>
        </div>
    );
};

export default Team;
