import React, { useState, useCallback, useEffect } from 'react'
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap'
import { AiFillDashboard, AiFillPoundCircle, AiFillClockCircle, AiFillCreditCard, AiFillCalendar } from 'react-icons/ai'
import base from '../../services/BaseService'
import Spinner from '../shared/loading'
import FreeAgent from '../../assets/icons/freeagent-mark.svg'
import Invoice from '../../assets/icons/invoice.svg'
import Refresh from '../../assets/icons/refresh.svg'
import CheckOutline from '../../assets/icons/check_outline.svg'
import refresh from '../../assets/icons/refresh24.svg'
import InvoiceModal from './reports/toinvoice'
import { AuthenticatedTemplate } from "@azure/msal-react";
import ConfirmToast from '../shared/confirmToast'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement, LineElement, PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactSelect from 'react-select'
import { useUserContext } from '../context/userContext'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement, PointElement, LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const { user } = useUserContext();
    const [style1, setStyle1] = useState("b-width pc-btn-dark ms-1 mb-1");
    const [style2, setStyle2] = useState("b-width pc-btn ms-1 mb-1");
    const [bankValues, setBankValues] = useState([]);
    const [bankValues1, setBankValues1] = useState([]);
    const [tsValues, setTsValues] = useState([]);
    const [tsValues1, setTsValues1] = useState([]);
    const [trValues, setTrValues] = useState([]);
    const [tsLabels, setTsLabels] = useState([]);
    const [trLabels, setTrLabels] = useState([]);
    const [vatValue, setVatValue] = useState([]);
    const [ccIn, setCCIn] = useState([]);
    const [ccOut, setCCOut] = useState([]);
    const [ccNet, setCCNet] = useState([]);
    const [ccTotal, setCCTotal] = useState([]);
    const [sumBillable, setSumBillable] = useState([]);
    const [sumUnbillable, setSumUnbillable] = useState([]);
    const [lastRefreshed, setLastRefreshed] = useState([]);
    const [periodDate, setPeriodDate] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(false);
    const [faRefresh, setFARefresh] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [invCreate, setInvCreate] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [handleConfirm, setHandleConfirm] = useState("")
    const [selectedValue, setSelectedValue] = useState(user.idUser);
    const [billablePeriod, setBillablePeriod] = useState([]);
    const [unbillablePeriod, setUnbillablePeriod] = useState([]);
    const [billableTotal, setBillableTotal] = useState([]);
    const [unbillableTotal, setUnbillableTotal] = useState([]);
    const [viewBillable, setViewBillable] = useState(true);
    const [periodLabels, setPeriodLabels] = useState([]);

    useEffect(() => {
        document.title = 'Dashboard | Mobyte';
    }, []);

    // set value for default selection

    const openModal = () => {
        if (user.userLevel === "10") {
            setInvCreate(false);
            setShowModal(!showModal);
        } else {
            window.alert("User not authorised")
        }
    }

    const openModalCreate = () => {
        if (user.userLevel === "10") {
            setInvCreate(true);
            setShowModal(true);
        } else {
            window.alert("User not authorised")
        }
    }

    useEffect(() => {
        if (handleConfirm === "Yes") {
            setLoading4(true);
            // setLoading5(true);
            setLastRefreshed("Refresh In Progress...");
            base.getAll("FreeAgent/refresh").then(() => {
                setLastRefreshed("Refreshing Dashboard...");
                var date = new Date().getTime()
                setFARefresh(date)
                setShowModal(true)
                setLoading4(false);
            });
        }
        if (handleConfirm === "No") {
            setShowModal(true)
        }
        setShowConfirm(false)
        setHandleConfirm("")
    }, [handleConfirm]);

    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 750 ? false : true)


    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 750) {
                setSmallScreen(false)
            } else {
                setSmallScreen(true)
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const closeModal = () => {
        setShowModal(false);
        setToggleModal(!toggleModal)
    }

    // handle onChange event of the dropdown and get value
    // React-select get value on change
    const handleChange = e => {
        setStyle2("b-width pc-btn me-1");
        setStyle1("b-width pc-btn-dark me-1")
        setSelectedValue(e.value);
    }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const formatNumberSingle = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(1)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const handleClick = async (e) => {
        if (e.target.name === 'Project') {
            setLoading2(true);
            setStyle2("b-width pc-btn me-1");
            setStyle1("b-width pc-btn-dark me-1")
            const tsRes = await base.getAll(`Report/tsperiod/${selectedValue}`)
            setTsLabels(tsRes.map(function (item) {
                return item.projectName;
            }));
            setTsValues(tsRes.map(function (item) {
                return item.sumBillable;
            }));
            setTsValues1(tsRes.map(function (item) {
                return item.sumUnbillable;
            }));
            setLoading2(false)


        };
        if (e.target.name === 'Task') {
            setLoading2(true);
            setStyle1("b-width pc-btn me-1");
            setStyle2("b-width pc-btn-dark me-1")
            const tsRes = await base.getAll(`Report/tsperiod/task/${selectedValue}`)
            setTsLabels(tsRes.map(function (item) {
                return item.taskName;
            }));
            setTsValues(tsRes.map(function (item) {
                return item.sumBillable;
            }));
            setTsValues1(tsRes.map(function (item) {
                return item.sumUnbillable;
            }));
            setLoading2(false)
        }
    };

    const openFreeAgent = (() => {
        window.open("https://mobyte.freeagent.com/overview");
    });

    const options2 = {
        responsive: true,
    };


    useEffect(() => {
        const getCCData = async () => {
            try {
                const ccRes = await base.getAll(`Report/ccfinal/${selectedValue}`)
                setPeriodLabels(ccRes.map(function (item) {
                    return item.period;
                }));
                setBankValues(ccRes.map(function (item) {
                    return item.ccIn;
                }));
                setBankValues1(ccRes.map(function (item) {
                    return item.ccOut;
                }));
                const result = ccRes.reduce((total, currentValue) => total = total + currentValue.ccIn, 0);
                const result1 = ccRes.reduce((total, currentValue) => total = total + currentValue.ccOut, 0);
                setCCIn(formatNumber(result));
                setCCOut(formatNumber(result1));
                setCCNet(formatNumber(result - result1))
                setCCTotal(formatNumber(ccRes[0].ccTotal))
            }
            catch (e) { console.log(e) }
            finally { setLoading1(false) }
        }
        const getTSData = async () => {
            try {
                const tsRes = await base.getAll(`Report/tsperiod/${selectedValue}`)
                setTsLabels(tsRes.map(function (item) {
                    return item.projectName;
                }));
                setTsValues(tsRes.map(function (item) {
                    return item.sumBillable;
                }));
                setTsValues1(tsRes.map(function (item) {
                    return item.sumUnbillable;
                }));
                setSumBillable(formatNumber(tsRes.reduce(function (prev, current) {
                    return prev + +current.sumBillable;
                }, 0)))
                setSumUnbillable(formatNumber(tsRes.reduce(function (prev, current) {
                    return prev + +current.sumUnbillable;
                }, 0)));
            } catch (e) {
                console.log(e);
            }
            finally {
                setLoading2(false);
            }
        }

        const getTSData6 = async () => {
            try {
                const tsRes = await base.getAll(`Report/tsperiod/${selectedValue}/all`)
                setPeriodLabels(tsRes.map(function (item) {
                    return item.period;
                }));
                setBillablePeriod(tsRes.map(function (item) {
                    return item.sumBillable;
                }));
                setUnbillablePeriod(tsRes.map(function (item) {
                    return item.sumUnbillable;
                }));
                const totalBillable = tsRes.reduce((total, currentValue) => total + currentValue.sumBillable, 0);
                const totalUnbillable = tsRes.reduce((total, currentValue) => total + currentValue.sumUnbillable, 0);

                const totalSum = totalBillable + totalUnbillable;

                // Calculate percentages
                const billablePercentage = totalSum > 0 ? (totalBillable / totalSum) * 100 : 0;
                const unbillablePercentage = totalSum > 0 ? (totalUnbillable / totalSum) * 100 : 0;
                setBillableTotal(formatNumberSingle(billablePercentage));
                setUnbillableTotal(formatNumberSingle(unbillablePercentage));
            }
            catch (e) { console.log(e); }
            finally {
                setLoading1(false);
                setLoading3(false);
            }
        }

        const getTRData = async () => {
            const tsRes = await base.getAll("Report/transactionReport")
            setTrLabels(tsRes.map(function (item) {
                return item.name;
            }));
            setTrValues(tsRes.map(function (item) {
                return item.rVAT;
            }));
            setVatValue(tsRes.map(datum => datum.rVAT).reduce((a, b) => a + b))
            setLoading2(false);

        }
        const getOverviewData = async () => {
            const overviewRes = await base.getAll(`Report/overview/${selectedValue}`)
            setLastRefreshed(new Date(overviewRes.lastrefreshed).toLocaleDateString() + ' ' + new Date(overviewRes.lastrefreshed).toLocaleTimeString());
        };

        const getDate = () => {
            const current = new Date();
            current.setDate(current.getDate() - 3);
            var date = current.getFullYear() + '.' + String(current.getMonth() + 1).padStart(2, '0');
            setPeriodDate(date);
            setLoading4(false);
        }
        const getUsers = async () => {
            const userRes = await base.getAll(`Report/users/${selectedValue}`)
            let detailArr = [];
            userRes.map(element => {
                let dropDownEle = { value: element["idUser"], label: element["name"] };
                detailArr.push(dropDownEle);
                return 1;
            });
            if (user.userLevel === '10') {
                detailArr.push({ value: "0", label: "All" })
            }
            setUsers(detailArr)
        };
        getDate();
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        getOverviewData();
        if (user.userLevel === "10") {
            getCCData();
            getTSData();
            getUsers();
            getTSData6();
        }
        if (user.userLevel === "20") {
            getTRData();
        }
        if (user.userLevel === "30") {
            getTSData();
            getTSData6();
        }

    }, [selectedValue, faRefresh, refreshPage, formatNumber, formatNumberSingle, user])

    const refreshFromFreeAgent = (async () => {
        setLoading4(true);
        setLastRefreshed("Refresh In Progress...");
        await base.getAll("FreeAgent/refresh")
        setLastRefreshed("Refreshing Dashboard...");
        setFARefresh(!faRefresh)
    }
    );

    const callRefresh = () => {
        setRefreshPage(!refreshPage);
    };

    const data = {
        labels: periodLabels,
        datasets: [
            {
                label: viewBillable ? 'Billable' : 'CC In',
                backgroundColor: 'rgb(34,85,96, 0.85)',
                data: viewBillable ? billablePeriod : bankValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        if (viewBillable) {
                            return value
                                .toFixed(1)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
                                + '%';

                        } else {
                            return value
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                        }

                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }, {
                label: viewBillable ? 'Unbillable' : 'CC Out',
                backgroundColor: 'rgba(220, 20, 60, 0.85)',
                data: viewBillable ? unbillablePeriod : bankValues1,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        if (viewBillable) {
                            return value
                                .toFixed(1)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
                                + '%';

                        } else {
                            return value
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                        }
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }
        ],
    }
    const data1 = {
        labels: tsLabels,
        datasets: [
            {
                label: 'Billable',
                backgroundColor: 'rgb(34,85,96, 0.85)',
                data: tsValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            },
            {
                label: 'Unbillable',
                backgroundColor: 'rgba(139, 197, 197, 0.85)',
                data: tsValues1,
                fill: true,
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }
        ],
    }

    const data3 = {
        labels: trLabels,
        datasets: [
            {
                label: 'Name',
                backgroundColor: 'rgb(34,85,96, 0.85)',
                data: trValues,
                fill: true,
                datalabels: {
                    color: '#111',
                    align: 'top',
                    anchor: 'end',
                    display: true,
                    font: {
                        weight: 'bold'
                    }
                }
            }
        ],
    }

    const renderLoading = () => (
        <div className="row align-items-center" style={{ height: "45px" }}>
            <div className="text-center" >
                <Spinner />
            </div>
        </div>
    );

    const renderCC = () => (
        <>
            <Row>
                <Col className="card-sub-text text-center">Incoming:</Col>
                <Col className="card-sub-text text-center">Outgoing:</Col>
                <Col className="card-sub-text text-center">Profit:</Col>
                <Col style={{ borderLeft: "#aaa solid 1px" }} className="card-sub-text text-center">PC Total:</Col>
            </Row>
            <Row>
                <Col className="card-sub-text-bold text-center">{ccIn}</Col>
                <Col className="card-sub-text-bold text-center">{ccOut}</Col>
                <Col className="card-sub-text-bold text-center">{ccNet}</Col>
                <Col style={{ borderLeft: "#aaa solid 1px" }} className="card-sub-text-bold text-center">{ccTotal}</Col>
            </Row>
        </>
    );

    const renderBillable = () => (
        <>
            <Row>
                <Col className="card-sub-text text-center">Total Billable:</Col>
                <Col className="card-sub-text text-center">Total Unbillable:</Col>
            </Row>
            <Row>
                <Col className="card-sub-text-bold text-center">{billableTotal}%</Col>
                <Col className="card-sub-text-bold text-center">{unbillableTotal}%</Col>
            </Row>
        </>
    );

    return (
        <AuthenticatedTemplate >
            {showConfirm &&
                <ConfirmToast
                    show={true}
                    style={{ top: "20%" }}
                    Message={"Would you like to refresh data from FreeAgent?"}
                    handleYes={() => {
                        setHandleConfirm("Yes");
                    }}
                    handleNo={() => {
                        setHandleConfirm("No");
                    }}
                ></ConfirmToast>
            }
            <div style={{ minHeight: "825px" }}>
                <CardHeader className="pt-2">
                    <Row>
                        <Col xs="12" sm="5" xl="9">
                            <AiFillDashboard size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                            <span className="section-heading">Dashboard</span>
                        </Col>
                        <Col xs="12" sm="7" xl="3" className="text-end" >
                            <div className="text-start me-1" >
                                <Row>
                                    <Col xs="10" >
                                        {user.userLevel === "10" &&
                                            (
                                                <ReactSelect
                                                    id="dropdown_cc"
                                                    className="text-lrf"
                                                    options={users}
                                                    placeholder={""}
                                                    maxWidth="300px"
                                                    onChange={handleChange}
                                                />
                                            )
                                        }
                                    </Col>
                                    <Col xs="2" className="text-end">

                                        <Button size="sm" color="light border-dark" className="btn-text" style={{ height: "38px" }} onClick={() => callRefresh()}>
                                            <img src={refresh} alt="refresh" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </CardHeader >


                <div className="container-fluid">

                    <div className="m-2">
                        <Row className="mt-2">
                            <Col xs="12" lg="12" xl="6">
                                <Card className="mt-2">
                                    <CardHeader>
                                        <Row>
                                            <Col xs="12" md="8">
                                                {viewBillable ?
                                                    (<AiFillCalendar size={30} className="mb-2" color="#8bc5c5" />)
                                                    :
                                                    (<AiFillPoundCircle size={30} className="mb-2" color="#8bc5c5" />)
                                                }
                                                <span className="portfolio-caption-heading">
                                                    {viewBillable ? " Chargeable Time" : " Profit Centre"}
                                                </span>
                                            </Col>
                                            {user.userLevel === "10" && (
                                                <Col className="text-end">
                                                    <Button id="CC" name="CC" className={"b-width pc-btn ms-1 mb-1"} onClick={() => setViewBillable(!viewBillable)}>
                                                        {viewBillable ? "CC" : "TS"}
                                                    </Button>
                                                </Col>
                                            )}

                                        </Row>
                                    </CardHeader>
                                    <CardHeader className="" style={{ backgroundColor: "#d0e7e7" }}>
                                        {loading3
                                            ? renderLoading()
                                            : viewBillable
                                                ? renderBillable()
                                                : renderCC()
                                        }

                                    </CardHeader>
                                    <CardBody>
                                        {loading1 ?
                                            <div className="row align-items-center" style={{ height: "350px" }}>
                                                <div className="text-center" >
                                                    <Spinner />
                                                </div>
                                            </div>

                                            :
                                            <Bar data={data} style={{ maxHeight: "350px" }} />
                                        }
                                    </CardBody>
                                </Card>

                            </Col>
                            {user !== "20" ?
                                <Col xs="12" lg="12" xl="6">
                                    <Card className="mt-2">
                                        <CardHeader>
                                            <Row>
                                                <Col xs="12" sm="6">
                                                    <AiFillClockCircle size={30} className="mb-2" color="#8bc5c5" />&nbsp;&nbsp;
                                                    <span className="portfolio-caption-heading">Time Tracking</span>
                                                </Col>
                                                <Col xs="12" sm="6" >
                                                    <div className="text-end">
                                                        <Button id="Project" name="Project" className={style1} onClick={handleClick}>Project</Button>
                                                        <Button id="Task" name="Task" className={style2} onClick={handleClick}>Task</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardHeader style={{ backgroundColor: "#d0e7e7" }}>
                                            {loading3 ?
                                                <div className="row align-items-center" style={{ height: "45px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <Row>
                                                        <Col className="card-sub-text text-center">Period:</Col>
                                                        <Col className="card-sub-text text-center">Billable:</Col>
                                                        <Col className="card-sub-text text-center">Unbillable:</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="card-sub-text-bold text-center">{periodDate}</Col>
                                                        <Col className="card-sub-text-bold text-center">{sumBillable}</Col>
                                                        <Col className="card-sub-text-bold text-center">{sumUnbillable}</Col>
                                                    </Row>
                                                </>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            {loading2 ?
                                                <div className="row align-items-center" style={{ height: "350px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :

                                                <Bar data={data1} options={options2} style={{ maxHeight: "350px" }} />
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                                :
                                <Col xs="12" lg="12" xl="6">
                                    <Card className="mt-2">
                                        <CardHeader>
                                            <Row>
                                                <Col xs="6">
                                                    <AiFillCreditCard size={30} className="mb-2" color="#8bc5c5" />&nbsp;&nbsp;
                                                    <span className="portfolio-caption-heading">Transaction Reconciliation</span>
                                                </Col>
                                                <Col xs="6" >
                                                    <div className="text-end">
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardHeader style={{ backgroundColor: "#d0e7e7" }}>
                                            {loading3 ?
                                                <div className="row align-items-center" style={{ height: "45px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <Row>
                                                        <Col className="card-sub-text text-center">VAT (To Reconcile)</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="card-sub-text-bold text-center">{vatValue}</Col>
                                                    </Row>
                                                </>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            {loading2 ?
                                                <div className="row align-items-center" style={{ height: "350px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :

                                                <Bar data={data3} options={options2} style={{ maxHeight: "350px" }} />
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-4">

                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-1 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => refreshFromFreeAgent()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Refresh Data &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">{lastRefreshed} &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                {loading4 ?
                                                    <div className="row align-items-center">
                                                        <div className="text-center" >
                                                            <Spinner />
                                                        </div>
                                                    </div>
                                                    :
                                                    <img src={Refresh} style={{ width: "50px" }} alt="" />

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-2 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openModal()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Invoice Report&nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Check invoices before batch run &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                <img src={CheckOutline} style={{ width: "50px" }} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-3 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openModalCreate()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Create Invoices &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Batch Monthly Invoice Run &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                {loading5 ?
                                                    <div className="row align-items-center">
                                                        <div className="text-center" >
                                                            <Spinner />
                                                        </div>
                                                    </div>
                                                    :
                                                    <img src={Invoice} style={{ width: "50px" }} alt="" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >

                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-4 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openFreeAgent()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">FreeAgent &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Open FreeAgent Application &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                <img src={FreeAgent} style={{ width: "50px" }} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                        </Row>
                    </div>

                </div>
                {showModal && <InvoiceModal closeModal={closeModal} showModalForm={true} displayModal={true} invCreate={invCreate} loading4={loading4} setLoading5={setLoading5} setLastRefreshed={setLastRefreshed} faRefresh={faRefresh} setFARefresh={setFARefresh} />}

            </div >
        </AuthenticatedTemplate>
    )
}

export default Dashboard