import React, { useState, useEffect } from "react";
import base from "../../../services/BaseService";
import { AiFillDashboard } from "react-icons/ai";
import { Col, Row, CardHeader, Button } from "reactstrap";
import ReactSelect from "react-select";
import Refresh24 from "../../../assets/icons/refresh24.svg";
import Spinner from "../../shared/loading";
import { useUserContext } from '../../context/userContext';

function PivotTable() {
    const { user } = useUserContext();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [selectedValue, setSelectedValue] = useState(user.idUser);
    const [users, setUsers] = useState([]);

    const formatNumber = (number) => {
        if (number === null || number === undefined) return "0.00";
        return number
            .toFixed(0)  // Ensure 2 decimal places
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$&,"); // Format with commas
    };

    const getData = async () => {
        setIsLoading(true);
        try {
            const res = await base.getAll(`report/ccfinalall/${selectedValue}`);

            // Extract and format unique years for dropdown
            const uniqueYears = [...new Set(res.map((row) => row.year))].sort((a, b) => a - b);
            const yearOptions = uniqueYears.map((year) => ({
                value: year,
                label: year.toString(),
            }));

            setYears(yearOptions);
            setSelectedYear(Math.max(...uniqueYears).toString());
            setData(res);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getUsers = async () => {
        try {
            const userRes = await base.getAll(`Report/users/${selectedValue}`);
            const userOptions = userRes.map((user) => ({
                value: user.idUser,
                label: user.name,
            }));
            userOptions.push({ value: "0", label: "All" });
            setUsers(userOptions);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        getUsers();
        getData();
        // only run on initial load
        // eslint-disable-next-line
    }, [selectedValue, refresh]);

    useEffect(() => {
        if (selectedYear) {
            setFilteredData(data.filter((row) => row.year === selectedYear));
        }
    }, [selectedYear, data]);



    const calculateTotals = (rows) =>
        rows.reduce(
            (totals, row) => ({
                ts: totals.ts + (row.ts || 0),
                tu: totals.tu + (row.tu || 0),
                ohd: totals.ohd + (row.ohd || 0),
                ohD_TU: totals.ohD_TU + (row.ohD_TU || 0),
                reallocIn: totals.reallocIn + (row.reallocIn || 0),
                reallocOut: totals.reallocOut + (row.reallocOut || 0),
                salary: totals.salary + (row.salary || 0),
                invValue: totals.invValue + (row.invValue || 0),
                debit: totals.debit + (row.debit || 0),
                credit: totals.credit + (row.credit || 0),
                other: totals.other +
                    (
                        row.tu +
                        row.ohd +
                        row.ohD_TU +
                        row.reallocIn +
                        row.reallocOut +
                        row.salary +
                        row.invValue +
                        row.debit +
                        row.credit
                        || 0
                    ),
                totalPeriod: totals.totalPeriod +
                    (
                        row.ts +
                        row.tu +
                        row.ohd +
                        row.ohD_TU +
                        row.reallocIn +
                        row.reallocOut +
                        row.salary +
                        row.invValue +
                        row.debit +
                        row.credit
                        || 0
                    ),
            }),
            {
                ts: 0,
                tu: 0,
                ohd: 0,
                ohD_TU: 0,
                reallocIn: 0,
                reallocOut: 0,
                salary: 0,
                invValue: 0,
                debit: 0,
                credit: 0,
                other: 0,
                totalPeriod: 0,
            }
        );

    const currentYearTotals = calculateTotals(filteredData);
    const overallTotals = calculateTotals(data);

    const numberStyle = (value) => ({
        color: value < 0 ? "red" : "black",
        textAlign: "right",
        fontSize: "13px",
    });

    const RenderLoading = () => (
        <div className="row align-items-center" style={{ height: "45px" }}>
            <div className="text-center">
                <Spinner />
            </div>
        </div>
    );

    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="12" xl="8">
                        <AiFillDashboard size={30} color="#8bc5c5" />
                        <span className="ms-2 section-heading">CC Report</span>
                    </Col>
                    <Col xs="12" xl="4" className="text-end">
                        <Row>
                            <Col xs="3" className="text-start">
                                <ReactSelect className="text-lrf" options={years} onChange={(e) => setSelectedYear(e.value)} value={years.find((y) => y.value === selectedYear)} />
                            </Col>
                            <Col xs="7" className="text-start">
                                <ReactSelect className="text-lrf" options={users} onChange={(e) => setSelectedValue(e.value)} value={users.find((u) => u.value === selectedValue)} />
                            </Col>
                            <Col xs="2">
                                <Button size="sm" color="light border-dark" onClick={() => setRefresh(!refresh)}>
                                    <img src={Refresh24} alt="refresh" />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>

            <div className="p-3" style={{ minHeight: "750px" }}>
                {isLoading ? (
                    <RenderLoading />
                ) : (
                    <table className="table table-bordered text-center">
                        <thead className="table-light cc-header" style={{ fontWeight: "normal" }}>
                            <tr>
                                <th>Period</th>
                                <th className="show-xs">Invoiced</th>
                                <th className="show-xs">Unpaid</th>
                                <th className="hide-xs">OHD</th>
                                <th className="hide-xs">OHD Unpaid</th>
                                <th className="hide-xs">Reallocation In</th>
                                <th className="hide-xs">Reallocation Out</th>
                                <th className="hide-xs">Salary</th>
                                <th className="hide-xs">Other Invoices</th>
                                <th className="hide-xs">Debit</th>
                                <th className="hide-xs">Credit</th>
                                <th className="hide-md">Other</th>
                                <th className="show-xs">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row, index) => (
                                <tr key={index}>
                                    <td className="cc-row">{row.period}</td>
                                    <td className="show-xs" style={numberStyle(row.ts)}>{formatNumber(row.ts)}</td>
                                    <td className="show-xs" style={numberStyle(row.tu)}>{formatNumber(row.tu)}</td>
                                    <td className="hide-xs" style={numberStyle(row.ohd)}>{formatNumber(row.ohd)}</td>
                                    <td className="hide-xs" style={numberStyle(row.ohD_TU)}>{formatNumber(row.ohD_TU)}</td>
                                    <td className="hide-xs" style={numberStyle(row.reallocIn)}>{formatNumber(row.reallocIn)}</td>
                                    <td className="hide-xs" style={numberStyle(row.reallocOut)}>{formatNumber(row.reallocOut)}</td>
                                    <td className="hide-xs" style={numberStyle(row.salary)}>{formatNumber(row.salary)}</td>
                                    <td className="hide-xs" style={numberStyle(row.invValue)}>{formatNumber(row.invValue)}</td>
                                    <td className="hide-xs" style={numberStyle(row.debit)}>{formatNumber(row.debit)}</td>
                                    <td className="hide-xs" style={numberStyle(row.credit)}>{formatNumber(row.credit)}</td>
                                    <td className="hide-md" style={numberStyle(
                                        row.ohd +
                                        row.ohD_TU +
                                        row.reallocIn +
                                        row.reallocOut +
                                        row.salary +
                                        row.invValue +
                                        row.debit +
                                        row.credit
                                    )}>
                                        {formatNumber(
                                            row.ohd +
                                            row.ohD_TU +
                                            row.reallocIn +
                                            row.reallocOut +
                                            row.salary +
                                            row.invValue +
                                            row.debit +
                                            row.credit
                                        )}
                                    </td>
                                    <td className="show-xs" style={numberStyle(
                                        row.ts +
                                        row.tu +
                                        row.ohd +
                                        row.ohD_TU +
                                        row.reallocIn +
                                        row.reallocOut +
                                        row.salary +
                                        row.invValue +
                                        row.debit +
                                        row.credit
                                    )}>
                                        {formatNumber(
                                            row.ts +
                                            row.tu +
                                            row.ohd +
                                            row.ohD_TU +
                                            row.reallocIn +
                                            row.reallocOut +
                                            row.salary +
                                            row.invValue +
                                            row.debit +
                                            row.credit
                                        )}
                                    </td>
                                </tr>
                            ))}
                            {/* Yearly Totals */}
                            <tr className="cc-total" style={{ backgroundColor: "#f2f2f2" }}>
                                <td colSpan="1">Year</td>
                                {Object.values(currentYearTotals).map((value, idx) =>
                                    idx === 10 ? ( // Special rule for index 10 (Other column)
                                        <td key={idx} className="show-xs hide-md" style={numberStyle(value)}>
                                            {formatNumber(value)}
                                        </td>
                                    ) : (
                                        idx !== 10 && ( // Exclude Other column (index 10) from totals row display
                                            <td key={idx} className={idx === 11 || idx === 1 || idx === 0 ? "show-xs" : "hide-xs"} style={numberStyle(value)}>
                                                {formatNumber(value)}
                                            </td>
                                        )
                                    )
                                )}
                            </tr>

                            {/* Overall Totals */}
                            <tr className="cc-total" style={{ backgroundColor: "#d9d9d9" }}>
                                <td colSpan="1">Overall</td>
                                {Object.values(overallTotals).map((value, idx) =>
                                    idx === 10 ? ( // Special rule for index 10 (Other column)
                                        <td key={idx} className="show-xs hide-md" style={numberStyle(value)}>
                                            {formatNumber(value)}
                                        </td>
                                    ) : (
                                        idx !== 10 && ( // Exclude Other column (index 10) from totals row display
                                            <td key={idx} className={idx === 11 || idx === 0 || idx === 1 ? "show-xs" : "hide-xs"} style={numberStyle(value)}>
                                                {formatNumber(value)}
                                            </td>
                                        )
                                    )
                                )}
                            </tr>
                        </tbody>

                    </table>
                )}
            </div>
        </>
    );
}

export default PivotTable;
