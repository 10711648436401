import React from 'react';
import '../../assets/css/shared/Footer.scss'
import { NavItem, Nav, Row, Col } from 'reactstrap'
import { AiOutlineMail, AiOutlinePhone, AiFillLinkedin, AiOutlineX } from 'react-icons/ai'
import { Link } from 'react-router-dom';
// import { Widget, addResponseMessage } from 'react-chat-widget';

// import 'react-chat-widget/lib/styles.css';


const Footer = (props) => {

    // const [chatBox, showChatBox] = useState(false);
    // const [chatMessage, setChatMessage] = useState("");
    // const [fullChat, setFullChat] = useState([]);
    // const chatBodyRef = useRef(null);

    // const sendMessage = () => {
    //     if (chatMessage.trim() !== "") {
    //         if (fullChat.length === 0) {
    //             setFullChat(prevFullChat => [...prevFullChat, { text: chatMessage, user: true }, { text: "Hello. You are through to Mobyte support, How can we help?", user: false }]);
    //         } else {
    //             if (chatMessage === "Can you help me?") {
    //                 setFullChat(prevFullChat => [...prevFullChat, { text: chatMessage, user: true }, { text: "Of course. What issue are you facing?", user: false }]);
    //             } else {
    //                 setFullChat(prevFullChat => [...prevFullChat, { text: chatMessage, user: true }]);

    //             }
    //         }
    //     }
    //     setChatMessage(""); // Clear the input field after sending the message
    // }

    // useEffect(() => {
    //     if (chatBodyRef.current) {
    //         chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    //     }
    // }, [fullChat]);

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         sendMessage();
    //     }
    // };



    // const handleNewUserMessage = (newMessage) => {
    //     console.log(`New message incoming! ${newMessage}`);
    //     // Now send the message throught the backend API
    //     // addResponseMessage(response);
    // };

    return (
        <>
            {/* <Widget
                handleNewUserMessage={handleNewUserMessage}
                title="Mobyte support"
                subtitle={null}
                showCloseButton={true}
                emojis={false}
            /> */}


            {/* <div className="chat-icon" onClick={() => showChatBox(!chatBox)}>
                <div className="d-flex">
                    <IoChatbubbleEllipsesOutline size={35} />
                </div>
            </div>

            {chatBox && (
                <div className="chat-box" >
                    <div className="chat-header">
                        Mobyte support
                    </div>
                    <div className="chat-body" ref={chatBodyRef}>
                        {fullChat.map((message, index) => (
                            <div
                                key={index}
                                className={`chat-message-bubble ${message.user ? 'user-message' : 'received-message'}`}
                            >
                                {message.text}
                            </div>
                        ))}
                    </div>
                    <div className="chat-message">
                        <div className="d-flex">
                            <div style={{ flex: "1" }}>
                                <Input multiple={true} max={500} maxLength={500} onKeyDown={handleKeyDown} height={200} style={{ border: "0px" }} placeholder='Write a response...' value={chatMessage} onChange={(e) => setChatMessage(e.target.value)} />
                            </div>
                            <div style={{ flex: "0 0 40px" }} className='text-center pt-1'>
                                <IoSendOutline size={25} onClick={() => sendMessage()} />
                            </div>

                        </div>
                    </div>

                </div>
            )} */}

            <footer className="footer" style={{ color: "#111", borderTop: "#d0e7e7 solid 0.5px" }}>
                <div className="pt-5 pb-2" style={{ backgroundColor: "#ccffff" }}>
                    <div className="container-large">
                        <Row>
                            <Col xs="12" lg="3" xl="3" className="mb-5">
                                <div className="fw-normal" >Mobyte</div>
                                <div className="d-flex flex-row">
                                    <div className="me-9">
                                        <address className="footer-item">
                                            6 Mulberry Green <br />
                                            Harlow <br />
                                            Essex<br />
                                            CM17 0EY
                                        </address>
                                        <div>
                                            <span>
                                                <a className="footer-link" href="mailto:info@mobyte.com"><AiOutlineMail style={{ color: "#111", fontSize: "17px" }} />&nbsp;<span >info@mobyte.com</span></a>
                                            </span>
                                            <span>
                                                <a className="footer-link" href="tel:+44 1279 897206"><AiOutlinePhone style={{ color: "#111", fontSize: "18px" }} />&nbsp;&nbsp;01279 897206</a>
                                            </span>
                                        </div>

                                    </div>


                                </div>
                            </Col>
                            <Col xs="12" md="4" lg="3" xl="2" className="mb-5">
                                <div className="fw-normal">Our work</div>
                                <Nav vertical>
                                    <NavItem>
                                        <Link to='/services' className="footer-link ps-0 py-2">
                                            Services
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to='/process' className="footer-link ps-0 py-2">
                                            Process
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to='/projects' className="footer-link ps-0 py-2">
                                            Projects
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem>
                                        <Link to='/technology' className="footer-link ps-0 py-2">
                                            Technology
                                        </Link>
                                    </NavItem> */}
                                </Nav>
                            </Col>
                            <Col xs="12" md="4" lg="3" xl="2" className="mb-5">
                                <div className="fw-normal">Company</div>
                                <Nav vertical>
                                    <NavItem>
                                        <Link to='/about' className="footer-link ps-0 py-2">
                                            About Us
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to='/team' className="footer-link ps-0 py-2">
                                            Team
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/careers" className="footer-link ps-0 py-2">
                                            Careers
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to='/contact' className="footer-link ps-0 py-2">
                                            Contact
                                        </Link>
                                    </NavItem>

                                </Nav>
                            </Col>

                            <Col xs="12" md="4" lg="3" xl="2" className="mb-5">
                                <div className="fw-normal">Resources</div>
                                <Nav vertical>
                                    <NavItem>
                                        <Link to='/blog' className="footer-link ps-0 py-2">
                                            Blog
                                        </Link>
                                    </NavItem>
                                    {/* <NavItem>
                                        <Link to='/news' className="footer-link ps-0 py-2">
                                            News
                                        </Link>
                                    </NavItem> */}
                                </Nav>
                            </Col>
                            <Col xs="0" xl="1"></Col>
                            <Col xs="12" sm="6" xl="2" className="mb-5">
                                <div className="fw-normal">Social</div>
                                <Row className="ps-0 py-3">
                                    <Col xs="2" className="me-2">
                                        <a style={{ color: "#111" }} href="https://x.com/Mobyte" target="_blank" rel="noopener noreferrer"> <AiOutlineX size={35} /></a>
                                    </Col>
                                    <Col xs="2">
                                        <a style={{ color: "#111" }} href="https://www.linkedin.com/company/5816009" target="_blank" rel="noopener noreferrer"> <AiFillLinkedin size={35} /></a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <section style={{ paddingTop: "30px", paddingBottom: "10px", backgroundColor: "#008080" }}>
                    <div className="container-large">
                        <Row>
                            <Col xs="12" xl="5" className=" mb-4">
                                <Row>

                                    <Col xs="12" sm="4">
                                        <Link to='/terms' className="base-item" title="Terms of Use">
                                            Terms of Use
                                        </Link>
                                    </Col>
                                    <Col xs="12" sm="4" href="#" title="Privacy Policy">
                                        <Link to='/privacy' className="base-item" >
                                            Privacy Policy
                                        </Link>
                                    </Col>
                                    <Col xs="12" sm="4" href="#" title="Privacy Policy">
                                        <Link to='/cookie' className="base-item" >
                                            Cookie Policy
                                        </Link>
                                    </Col>

                                </Row>
                                {/* </Nav> */}
                            </Col>
                            <Col xs="12" xl="7" className="mb-4 text-end">

                                <p className="base-item mb-0">
                                    &copy;{new Date().getFullYear()} Mobyte. All rights reserved.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </section>
            </footer>
        </>
    );
}

export default Footer