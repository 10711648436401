import React, { useEffect, useLayoutEffect } from 'react';
import '../../App.css';
import { Route, Routes, useLocation } from 'react-router-dom'
import Navbar from '../shared/navbar.js';
import Footer from '../shared/footer.js';
import Error404 from '../shared/error404.js';
import Services from '../mainsite/services/services.js';
import Process from '../mainsite/services/process.js';
import Projects from '../mainsite/projects/projects.js';
import Technology from '../mainsite/other/technology.js';
import Team from '../mainsite/company/team.js';
import Careers from '../mainsite/company/careers.js';
import About from '../mainsite/company/about.js';
import Contact from '../mainsite/contact/contact.js';
import Privacy from '../mainsite/terms/privacy.js';
import Cookie from '../mainsite/terms/cookie.js';
import Blog from '../mainsite/resources/blog.js';
import Bespoke from '../mainsite/resources/bespoke.js';
import Responsibility from '../mainsite/terms/responsibility.js';
import Terms from '../mainsite/terms/terms.js';
import Home from '../mainsite/homepage/homepage.js';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ServiceDetail from '../mainsite/services/servicesDetail.js';
import ProjectDetail from '../mainsite/projects/projectDetail.js';
import { CookieProvider } from '../shared/cookieContext.js';
import CookieBanner from '../shared/cookieBanner.js';
import AuthenticatedRoutes from './AuthenticatedRoutes.js';
import { UserProvider } from '../context/userContext.js';
import Homepage from '../mainsite/homepage/homepageNew.js';
import Login from '../mainsite/login/login.js';

const PrivateRoute = () => {
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const location = useLocation();

    useEffect(() => {
        const setCanonicalUrl = () => {
            const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
            let link = document.querySelector("link[rel='canonical']");

            if (!link) {
                link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                document.head.appendChild(link);
            }
            link.setAttribute('href', canonicalUrl);
        };

        setCanonicalUrl();
    }, [location]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        // Define the scroll handler
        const handleScroll = () => {
            // Select the elements you want to check visibility for
            const elements = document.querySelectorAll('.div-container, .block-left-scroll, .block-right-scroll');

            // Loop through each element and check if it's in the viewport
            elements.forEach((element) => {
                const positionFromTop = element.getBoundingClientRect().top;
                const threshold = window.innerHeight; // Use window.innerHeight for viewport height

                // If the element is within the viewport and does not yet have 'visible'
                if (positionFromTop < threshold && positionFromTop >= 0) {
                    element.classList.add('visible');
                }
                // Do NOT remove the class once added
            });
        };

        // Attach the scroll event listener to the window
        window.addEventListener('scroll', handleScroll);

        // Initial check to handle elements that might already be visible on load
        handleScroll();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <>
            <UnauthenticatedTemplate>
                <div className="page-container">
                    <div className="content-wrap">
                        <Navbar loggedInName={name} />
                        <div style={{ paddingTop: "70px" }}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/services" element={<Services />} />
                                <Route path="/team" element={<Team />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/careers" element={<Careers />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/cookie" element={<Cookie />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/responsibility" element={<Responsibility />} />
                                <Route path="/bespoke/:blogId" element={<Bespoke />} />
                                <Route path="/blog" element={<Blog />} />
                                <Route path="/technology" element={<Technology />} />
                                <Route path="/process" element={<Process />} />
                                <Route path="/projects" element={<Projects />} />
                                <Route path="/projects/:projectId" element={<ProjectDetail />} />
                                <Route path="/services/:serviceId" element={<ServiceDetail />} />
                                <Route path="/homepageV2" element={<Homepage />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="*" element={<Error404 />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                    <CookieProvider>
                        <CookieBanner>
                        </CookieBanner >
                    </CookieProvider>
                </div>

            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <UserProvider>
                    <AuthenticatedRoutes />
                </UserProvider>
            </AuthenticatedTemplate>

        </>
    );
}

export default PrivateRoute;
