import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import airBP from "../../../assets/images/air_bp_RGB.svg"
import flowserve from "../../../assets/images/flowserve.svg"
import bplogo from "../../../assets/images/bp-logo-primary.svg"
import infosys from "../../../assets/images/infosys-logo-jpeg.jpg"
import { MdOutlineHandshake, MdOutlineEventRepeat } from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const iconSize = 100; // set the size of the icons

const infoCards = [
    {
        heading: "Trusted Relationships",
        icon: <MdOutlineHandshake size={iconSize} />,
        text: "We prioritize building strong, dependable connections with our clients, ensuring you always have a partner who truly understands and supports your unique goals.",
    },
    {
        heading: "High Client Retention",
        icon: <MdOutlineEventRepeat size={iconSize} />,
        text: "Our unwavering commitment to responsive and reliable support has earned us the trust of our clients, who consistently choose Mobyte for their evolving IT needs.",
    },
    {
        heading: "Expert Management",
        icon: <AiOutlineSafetyCertificate size={iconSize} />,
        text: "With extensive expertise in integrating and managing complex systems, we ensure your IT infrastructure is seamless, secure, and optimized for the long haul.",
    },

];


export default function Customers() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`container-large pb-5 pt-5 block-left-scroll ${isVisible ? 'visible' : ''}`}>
            <div className="hero-text-1 accent-5-text pb-3">
                Why partner with Mobyte?
            </div>
            <div className="large-text">
                At Mobyte, we don’t just build software — we build <span className="client-section-emphasis">long-term partnerships</span>.
                Our goal is to be more than just a service provider; we strive to become
                a trusted extension of your team, helping your business grow and adapt in
                an ever-evolving digital landscape.
            </div>
            <Row className="container-large pt-4">
                <Col xs="12" className="d-flex justify-content-center ">
                    <div className="">
                        <Row>
                            {infoCards.map((item, index) => {
                                return (
                                    <Col key={index} lg="4" xs="12" className="contact-hooks pt-3 pb-3">
                                        <Card className="bgcol-secondary">
                                            <CardBody className="p-4">
                                                <div className="primary-text">{item.icon}</div>
                                                <div className="hero-text-4 header-brush pt-3">{item.heading}</div>
                                                <div className="standard-text accent-6">{item.text}</div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="hero-text-3 pt-5">
                Companies who trust our expertise
            </div>
            <Row className="mt-2 pb-5">
                <Col xl="3" xs="12" className="client-section-logos" >
                    <img className="logo-rect" src={airBP} alt="air bp logo" />
                </Col>
                <Col xl="3" xs="12" className="client-section-logos" >
                    <img className="logo-rect" src={flowserve} alt="flowserve logo" />
                </Col>
                <Col xl="3" xs="12" className="client-section-logos" >
                    <img className="logo-square" src={bplogo} alt="bp logo" />
                </Col>
                <Col xl="3" xs="12" className="client-section-logos" >
                    <img className="logo-rect" src={infosys} alt="infosys logo" />
                </Col>
            </Row>
        </div>
    );
}
